var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.mobile)?_c('div',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"5"}},[_c('v-list',{staticClass:"pa-0 pr-1 pl-1",staticStyle:{"overflow":"auto"},attrs:{"nav":"","color":"#EEEEEE","height":_vm.height}},[_c('v-list-item-group',{model:{value:(_vm.categoryIndex),callback:function ($$v) {_vm.categoryIndex=$$v},expression:"categoryIndex"}},_vm._l((_vm.filterValues),function(category){return _c('v-list-item',{key:category.value,attrs:{"dense":""}},[_c('v-list-item-content',[_vm._v(" "+_vm._s(category.label)+" ")]),_c('v-list-item-action',[(_vm.getFilters[category.value].length)?_c('v-badge',{attrs:{"content":_vm.getFilters[category.value].length,"color":"#1FAFAB"}}):_vm._e()],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"cols":"7"}},[_c('div',{style:(("height: " + _vm.height + "px; overflow: auto"))},[(_vm.categoryIndex !== undefined)?_c('v-list',{attrs:{"flat":""}},[_c('v-list-item-group',{attrs:{"value":_vm.selectedValues,"multiple":""},on:{"change":_vm.selectValue}},_vm._l((_vm.shownValues),function(item){return _c('v-list-item',{key:item[_vm.selectedCategory],attrs:{"value":item[_vm.selectedCategory]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-checkbox',{attrs:{"input-value":active,"color":"#1FAFAB"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item[_vm.selectedCategory]))])],1)]}}],null,true)})}),1)],1):_vm._e()],1)])],1)],1):_c('v-row',{staticClass:"pt-6",attrs:{"no-gutters":"","align":"end"}},[_c('v-sheet',{attrs:{"max-width":"1480","width":_vm.width,"height":"40","color":"#f8f8f8"}},[_c('v-slide-group',{attrs:{"show-arrows":"","center-active":""}},_vm._l((_vm.filterValues),function(filter,i){return _c('v-slide-item',{key:filter.value,staticClass:"filter"},[_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"pr-1 pt-1 mr-1",attrs:{"depressed":"","color":"#f8f8f8","height":"40"},on:{"click":function($event){return _vm.selectCategory(i)}}},'v-btn',attrs,false),on),[_c('v-badge',{staticStyle:{"font-size":"1.3em","font-weight":"bold"},attrs:{"offset-x":"12","offset-y":"10","value":_vm.getFilters[filter.value].length > 0,"content":_vm.getFilters[filter.value].length,"color":"#1FAFAB"}},[_vm._v(" "+_vm._s(filter.label)+" "),_c('span',{staticStyle:{"margin-left":"-7px"}},[_c('v-icon',[_vm._v(" mdi-chevron-"+_vm._s(attrs['aria-expanded'] === 'true' ? 'up' : 'down')+" ")])],1)])],1)]}}],null,true)},[(_vm.categoryIndex === i)?_c('v-card',{attrs:{"width":"240","height":"300"}},[_c('v-list',{attrs:{"elevation":"0","flat":""}},[_c('v-list-item-group',{attrs:{"value":_vm.selectedValues,"multiple":""},on:{"change":_vm.selectValue}},_vm._l((_vm.getFilterEntities[filter.value]),function(item){return _c('v-list-item',{key:item[filter.value],attrs:{"value":item[filter.value]},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',{staticClass:"mr-2"},[_c('v-checkbox',{attrs:{"input-value":active,"color":"#1FAFAB"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item[filter.value]))])],1)]}}],null,true)})}),1)],1)],1):_vm._e()],1)],1)}),1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }